import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }) {
	const auth = useSelector((state) => state.auth.user);
	const stateLoanGuid = useSelector((state) => state.loanPipeline.selectedGuid);
	const stateConnectionType = useSelector((state) => state.websocket.connectionType);
	const requestedPath = useSelector((state) => state.auth.requestedPath);

	if (auth.username) {
		if (stateLoanGuid) {
			return <Navigate to='/tbw/loan' />;
		} else {
			if (stateConnectionType === 'MQTT' || stateConnectionType === 'SSF') {
				return <Navigate to='/dashboard' />;
			} else {
				return requestedPath !== '' ? (
					<Navigate to={requestedPath} />
				) : (
					<Navigate to='/dashboard' />
				);
			}
		}
	}

	return children;
}

export default GuestGuard;
