import rg4js from 'raygun4js';

//TODO
// Replace with an API call so only authorized users can obtain the key
const { REACT_APP_RAYGUN_KEY } = process.env;

export const raygunInit = async (user) => {
	const { username, email, clientId } = user;
	rg4js('apiKey', REACT_APP_RAYGUN_KEY);
	rg4js('enableCrashReporting', true);

	rg4js('setUser', {
		identifier: username,
		isAnonymous: false,
		email: email,
		clientId: clientId,
	});

	try {
		throw new Error('testing 123');
	} catch (e) {
		//ignore
	}

	//TODO
	// see all options at https://github.com/MindscapeHQ/raygun4js

	rg4js('options', {
		allowInsecureSubmissions: false,
		ignoreAjaxAbort: true,
		//     ignoreAjaxError: true,
		debugMode: process.env.NODE_ENV !== 'production' ? true : false,
		//     ignore3rdPartyErrors: false,
		//     wrapAsynchronousCallbacks: true,
		//     excludedHostnames: ['\.local'],
		//     excludedUserAgents: ['Mosaic'],
		//     disableErrorTracking: false,
		//     disablePulse: false,
		//     pulseMaxVirtualPageDuration: 1800000,
		//     pulseIgnoreUrlCasing: false,
		//     captureUnhandledRejections: true,
		//     setCookieAsSecure: false,
		//     captureMissingRequests: false,
		//     automaticPerformanceCustomTimings: false
	});
};
