import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { getTheme } from './../../redux/theme/themeSlice';
import { getUser } from '../../redux/auth/authSlice';
import { getSelectedGuid } from '../../redux/loanPipeline/loanPipelineSlice';

import { green } from '@mui/material/colors';

import { ListItemButton, Tooltip, Grid } from '@mui/material';

import { ReactComponent as LTKDarkThemeLogo } from './../../vendor/LTK Logo Dark Theme Vector-ai.svg';
import { ReactComponent as LTKLightThemeLogo } from './../../vendor/LTK Logo Light Theme Vector-ai.svg';

import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';
import { removeGuardedRoutes } from './removeGuardedRoutes';

// import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as LennarLogo } from './../../vendor/Lennar_Mortgage.svg';


import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 255;

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	// justifyContent: 'flex-end',
}));

const Brand = styled(ListItemButton)`
	font-size: ${(props) => props.theme.typography.h5.fontSize};
	font-weight: ${(props) => props.theme.typography.fontWeightMedium};
	color: ${(props) => props.theme.sidebar.header.color};
	background-color: ${(props) => props.theme.sidebar.header.background};
	font-family: ${(props) => props.theme.typography.fontFamily};
	min-height: 56px;
	padding-left: ${(props) => props.theme.spacing(6)};
	padding-right: ${(props) => props.theme.spacing(6)};
	justify-content: center;
	cursor: pointer;
	flex-grow: 0;

	${(props) => props.theme.breakpoints.up('sm')} {
		min-height: 64px;
	}

	&:hover {
		background-color: ${(props) => props.theme.sidebar.header.background};
	}
`;

const LennarBrandIcon = styled(LennarLogo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;

const LogoStyling = `
	margin-right: ${(props) => props.theme.spacing(2)}px;
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 250px;
	height: 60px;
`;

const LTKDarkLogo = styled(LTKDarkThemeLogo)`
	${LogoStyling}
`;

const LTKLightLogo = styled(LTKLightThemeLogo)`
	${LogoStyling}
`;

const LTKLogo = () => {
	const theme = useSelector(getTheme);
	if (theme === 'NOTATHEME') {
		return <LTKLightLogo />; //DarkIcon looks best right now for all scenarios but can render light or dark if themes are changed
	} else {
		return <LTKDarkLogo />;
	}
};

const Sidebar = ({ items, showFooter = true, showMqttStatus = false, ...rest }) => {
	const user = useSelector(getUser);
	const [guardedItems, setGuardedItems] = useState(undefined);
	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const { clientId } = user;
		let licenses = [];
		if (clientId !== '7') {
			licenses.push('bridge', 'expert', 'optimized');
		}
		if (clientId === '7' || clientId === '2') {
			licenses.push('tbw');
		}
		console.log('ITEMS Sidebar', items);
		let guardedRoutes = removeGuardedRoutes(items, user, licenses);
		setGuardedItems(guardedRoutes);
	}, [items, user]);

	if (guardedItems && user) {
		return (
			<div>
				<Grid>
					<SidebarNav items={guardedItems} />
				</Grid>
			</div>
		);
	} else {
		return <React.Fragment />;
	}
};

export default Sidebar;
