import { Layout, List, Sliders, GitCommit, Tool } from 'react-feather';

// guards will work much the same as the route guards but rather than returning a component or a route, they'll just be used to filter what the sidebar displays
// thus these should match up to the guards that are applied to the routes

export const navItems = [
	{
		title: '',
		pages: [
			{
				href: '/dashboard',
				title: 'Dashboard',
				icon: Sliders,
			},
			{
				href: '/admin',
				icon: Layout,
				title: 'Admin',
				children: [
					{
						href: '/admin/companies',
						title: 'Companies',
						guards: {
							groupGuards: ['ltk-admin'],
						},
					},
					{
						href: '/admin/users',
						title: 'Users',
						guards: {
							groupGuards: ['company-admin'],
						},
					},
				],
			},
		],
	},
	{
		title: 'Tools',
		pages: [
			{
				href: '/bridge/tasks',
				icon: GitCommit,
				title: 'Bridge',
				guards: {
					licenseGuards: ['bridge'],
					groupGuards: ['company-user'],
				},
			},
			// NOT COMPLETE, HIDE ROUTE FOR NOW
			// {
			// 	href: '/tools/batch-folder-move',
			// 	icon: Tool,
			// 	title: 'Power Tools',
			// 	guards: {
			// 		licenseGuards: ['expert'],
			// 	},
			// },
			// {
			// 	href: '/tbw/pipeline',
			// 	icon: List,
			// 	title: 'Task Based Workflow',
			// 	guards: {
			// 		licenseGuards: ['tbw'], // Hide from non-LTK users for now
			// 	},
			// },
			//TODO
			// Implement licenseGuards: ['optimized']
		],
	},
	// THIS WILL BE HIDDEN FROM THE USER FOR NOW
	// {
	// 	title: 'Knowledge Base',
	// 	pages: [
	// 		{
	// 			href: '/documentation/welcome',
	// 			icon: BookOpen,
	// 			title: 'Documentation',
	// 			guards: {
	// 				groupGuards: ['ltk-user'], // Hide from non-LTK users for now
	// 			},
	// 		},
	// 		{
	// 			href: '/changelog',
	// 			icon: BookOpen,
	// 			title: 'Changelog',
	// 			badge: 'v3.1.0',
	// 			guards: {
	// 				groupGuards: ['ltk-user'], // Hide from non-LTK users for now
	// 			},
	// 		},
	// 	],
	// },
];
