import 'react-app-polyfill/stable';
import { BrowserRouter } from 'react-router-dom';
import Amplify, { Analytics } from 'aws-amplify';
import config from './aws-exports.js';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { inspect } from '@xstate/inspect';
const { NODE_ENV } = process.env;

let persistor = persistStore(store);

Amplify.configure(config);

//Only launch XState inspector in non-production environments
if (NODE_ENV !== 'production') {
	inspect({
		iframe: false,
	});
} else if (NODE_ENV === 'production') {
	//disable logs in prod
	console.log = () => { };
	console.error = () => { };
	console.debug = () => { };
}

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);
