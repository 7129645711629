import React from 'react';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import { Button, Paper, TextField as MuiTextField, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { Alert as MuiAlert } from '@mui/material';
import { ReactComponent as Logo } from '../../vendor/LTK Logo Dark Vector.svg';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
	padding: ${(props) => props.theme.spacing(6)};

	${(props) => props.theme.breakpoints.up('md')} {
		padding: ${(props) => props.theme.spacing(10)};
	}
`;

const BrandIcon = styled(Logo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;

function ConfirmResetPassword() {
	const navigate = useNavigate();

	return (
		<Wrapper>
			<Helmet title='Confirm Forgot Password' />
			<BrandIcon />
			<Typography component='h1' variant='h4' align='center' gutterBottom>
				Set a new password
			</Typography>
			<Typography component='h2' variant='body1' align='center'>
				Enter the verification code you were sent below and set a new password.
			</Typography>

			<Formik
				initialValues={{
					username: '',
					code: '',
					password: '',
					confirmPassword: '',
					submit: false,
				}}
				validationSchema={Yup.object().shape({
					code: Yup.string().min(4).max(8).required('Confirmation code is required'),
					password: Yup
						.string()
						.min(10)
						.max(255)
						.required('Password is required')
						.matches(/^.*(?=.{10,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
							  "Password must contain at least 10 characters, one uppercase, one number and one special case character"),
					confirmPassword: Yup.string().when('password', {
						is: (val) => (val && val.length > 0 ? true : false),
						then: Yup.string().oneOf([Yup.ref('password')], 'The passwords do not match'),
					}),
				})}
				onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
					try {
						Auth.forgotPasswordSubmit(values.username, values.code, values.password).then(
							(data) => {
								console.log('Password has been successfully reset');
								navigate('/auth/sign-in');
							}
						);
					} catch (error) {
						const message = error.message || 'Something went wrong';

						setStatus({ success: false });
						setErrors({ submit: message });
						setSubmitting(false);
					}
				}}>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					values,
				}) => (
					<form noValidate onSubmit={handleSubmit}>
						{errors.submit && (
							<Alert mt={2} mb={1} severity='warning'>
								{errors.submit}
							</Alert>
						)}
						<TextField
							variant='standard'
							type='username'
							name='username'
							label='Username'
							value={values.username}
							error={Boolean(touched.username && errors.username)}
							fullWidth
							helperText={touched.username && errors.username}
							onBlur={handleBlur}
							onChange={handleChange}
							my={3}
						/>
						<TextField
							variant='standard'
							type='text'
							name='code'
							label='Confirmation Code'
							value={values.code}
							error={Boolean(touched.code && errors.code)}
							fullWidth
							helperText={touched.code && errors.code}
							onBlur={handleBlur}
							onChange={handleChange}
							my={3}
						/>
						<TextField
							variant='standard'
							type='password'
							name='password'
							label='Enter a New Password'
							value={values.password}
							error={Boolean(touched.password && errors.password)}
							fullWidth
							helperText={touched.password && errors.password}
							onBlur={handleBlur}
							onChange={handleChange}
							my={3}
						/>
						<TextField
							variant='standard'
							type='password'
							name='confirmPassword'
							label='Confirm Password'
							value={values.confirmPassword}
							error={Boolean(touched.confirmPassword && errors.confirmPassword)}
							fullWidth
							helperText={touched.confirmPassword && errors.confirmPassword}
							onBlur={handleBlur}
							onChange={handleChange}
							my={3}
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							disabled={isSubmitting}>
							Reset password
						</Button>
					</form>
				)}
			</Formik>
		</Wrapper>
	);
}

export default ConfirmResetPassword;
