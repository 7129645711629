import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Sidebar from '../components/sidebar/Sidebar';
import Loader from '../components/Loader';
import Navbar from '../components/navbar/Navbar';
//import Footer from "../components/Footer";
import Settings from '../components/Settings';
import { getUser, setEncompassToken, userLogout } from './../redux/auth/authSlice';

import {
	Hidden,
	CssBaseline,
	Paper as MuiPaper,
	Drawer,
	AppBar as MuiAppBar,
	Toolbar,
	IconButton,
	Typography,
	Divider,
	Container,
	Tooltip,
	ListItemButton,
	Menu,
	MenuItem,
	Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getTheme } from './../redux/theme/themeSlice';
import { ReactComponent as LTKLightThemeLogo } from './../vendor/LTK Logo Light Theme Vector-ai.svg';
import { ReactComponent as LTKDarkThemeLogo } from './../vendor/LTK Logo Dark Theme Vector-ai.svg';
import { margin } from 'polished';
import Footer from '../components/sidebar/SidebarFooter'
import AccountCircle from '@mui/icons-material/AccountCircle';
import NavbarUserDropdown from '../components/navbar/NavbarUserDropdown';
import { Auth } from 'aws-amplify';

const Brand = styled(ListItemButton)`
	font-size: ${(props) => props.theme.typography.h5.fontSize};
	font-weight: ${(props) => props.theme.typography.fontWeightMedium};
	color: ${(props) => props.theme.sidebar.header.color};
	background-color: ${(props) => props.theme.sidebar.header.background};
	font-family: ${(props) => props.theme.typography.fontFamily};
	min-height: 56px;
	padding-left: ${(props) => props.theme.spacing(6)};
	padding-right: ${(props) => props.theme.spacing(6)};
	justify-content: center;
	cursor: pointer;
	flex-grow: 0;
	${(props) => props.theme.breakpoints.up('sm')} {
		min-height: 64px;
	}
	&:hover {
		background-color: ${(props) => props.theme.sidebar.header.background};
	}
`;

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const LogoStyling = `
	margin-right: ${(props) => props.theme.spacing(2)}px;
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 250px;
	height: 60px;
`;

const LTKDarkLogo = styled(LTKDarkThemeLogo)`
	${LogoStyling}
`;

const LTKLightLogo = styled(LTKLightThemeLogo)`
	${LogoStyling}
`;

const LTKLogo = () => {
	const theme = useSelector(getTheme);
	if (theme === 'NOTATHEME') {
		return <LTKLightLogo />; //DarkIcon looks best right now for all scenarios but can render light or dark if themes are changed
	} else {
		return <LTKDarkLogo />;
	}
};

const drawerWidth = 258;

const Root = styled('div')`
	display: flex;
	min-height: 100vh;
`;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const AppContent = styled('div')`
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
	flex: 1;
	background: ${(props) => props.theme.palette.background.default};

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: none;
	}

	.MuiPaper-root .MuiPaper-root {
		box-shadow: none;
	}
`;

const BaseDashboardLayout = ({ children, width, navItems, showMqttStatus }) => {
	const user = useSelector(getUser);
	const [mobileOpen, setMobileOpen] = useState(false);
	const [showThemeSelector, updateShowThemeSelector] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [containerWidth, setContainerWidth] = useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const nav = useNavigate();
	const dispatch = useDispatch();

	const smashToken = () => { 
		dispatch(setEncompassToken('pppp'));
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const showThemeSelectorToggler = () => {
		updateShowThemeSelector(!showThemeSelector);
	};

	const hideThemeSelector = () => {
		updateShowThemeSelector(false);
	};

	const theme = useTheme();
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

	const handleDrawerOpen = () => {
		setOpen(true);
		setContainerWidth(255)
	};

	const handleDrawerClose = () => {
		setOpen(false);
		setContainerWidth(0);
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		//route
	};

	const handleGoToProfile = () => {
		setAnchorEl(null);
		//route
		let path = `../profile/my-account`
		nav(path);
	};

	const handleGoToMyCompany = () => {
		setAnchorEl(null);
		//route
		let path = `../admin/my-company`
		nav(path);
	};

	const handleSignOut = async () => {
		try {
			await Auth.signOut();
			dispatch(userLogout());
		} catch (error) {
			console.log('error signing user out');
			console.log(error);
		}
	};

	if (user) {
		return (
			<Root>
				<CssBaseline />
				<AppBar sx={{
					background: '#233044',
					mr: 4,
					pl: 4,
				}} position="fixed" open={open}>
					<Toolbar>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							onClick={handleDrawerOpen}
							edge='start'
							sx={{ mr: 0, ...(open && { display: 'none' }) }}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							Menu
						</Typography>
						{/* <Tooltip title="Open settings">
							<IconButton sx={{ p: 0 }}>
								<Footer showMqttStatus={showMqttStatus} />
							</IconButton>
						</Tooltip> */}
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
                <MenuItem onClick={handleGoToProfile}>My Account</MenuItem>
				<MenuItem onClick={handleGoToMyCompany}>My Company</MenuItem>
				<MenuItem onClick={handleSignOut}>Sign out</MenuItem>
              </Menu>
					</Toolbar>
				</AppBar>
				<CssBaseline />
				<Drawer
					sx={{
						width: containerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: containerWidth,
							boxSizing: 'border-box',
							background: '#233044',
						},
					}}
					variant="persistent"
					anchor="left"
					open={open}
				>
					<DrawerHeader>
						<LTKLogo />
						<IconButton color='default' onClick={handleDrawerClose}>
							<MenuIcon />
						</IconButton>
					</DrawerHeader>
					<Divider />
					<Sidebar
						sx={{ display: { xl: 'none', xs: 'block' } }}
						PaperProps={{ style: { width: drawerWidth } }}
						variant='temporary'
						open={mobileOpen}
						onClose={handleDrawerToggle}
						items={navItems}
						showMqttStatus={showMqttStatus}
					/>
				</Drawer>
				<CssBaseline />
				<AppContent>
					<Navbar
						onDrawerToggle={handleDrawerToggle}
						toggleTheme={showThemeSelectorToggler}
					/>
					<MainContent p={isLgUp ? 12 : 5}>
						{children} <Outlet />
					</MainContent>
					{/* <Footer /> */}
				</AppContent>
				<Settings show={showThemeSelector} closed={hideThemeSelector} />
			</Root>
		);
	} else {
		return <Loader />;
	}
};

export default BaseDashboardLayout;
