/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:5831a4a3-c998-42e8-bcb3-e3a7d5a599b0",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ZGZmVLLVo",
    "aws_user_pools_web_client_id": "23hd9odgu904a7teg4a9a7mc85",
    "oauth": {
        "domain": "stageportalltkai.auth.us-west-2.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://t6y2qls2lja47b7qdn2yucwtx4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-cnbzhvglvnfopmzltuslxaoqnu",
    "aws_user_files_s3_bucket": "portal-storage103857-stage",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
