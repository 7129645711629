// guards will work much the same as the route guards but rather than returning a component or a route, they'll just be used to filter what the sidebar displays
// thus these should match up to the guards that are applied to the routes

import { ArrowLeftCircle, Key, Move, Archive, Package, Activity } from 'react-feather';

// If the user is hitting the portal for TBW purposes, will limit the routes
export const bridgeItems = [
	{
		title: '',
		pages: [
			{
				href: '/dashboard',
				icon: ArrowLeftCircle,
				title: 'Go Back',
			},
		],
	},
	{
		title: 'Bridge',
		pages: [
			// {
			// 	href: '/bridge/updates',
			// 	icon: Layers,
			// 	title: 'Updates',
			// },
			{
				href: '/bridge/configurations',
				icon: Key,
				title: 'Configurations',
				guards: {
					licenseGuards: ['bridge'],
					groupGuards: ['company-admin'],
				},
			},
			{
				href: '/bridge/data-mapping',
				icon: Move,
				title: 'Data Mapping Templates',
			},
			{
				href: '/bridge/document-mapping',
				icon: Archive,
				title: 'Document Mapping Templates',
			},
			{
				href: '/bridge/tasks',
				icon: Package,
				title: 'Tasks',
			},
			{
				href: '/bridge/logs',
				icon: Activity,
				title: 'Transactional Logs',
			},
		],
	},
];
