/* eslint-disable prettier/prettier */
import React from 'react';
import { Auth } from 'aws-amplify';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Paper, TextField as MuiTextField, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { Alert as MuiAlert } from '@mui/material';
//LENNAR BRAND: 1) the ltk dark logo is imported from the vendor folder here.
import { ReactComponent as Logo } from '../../vendor/LTK Logo Dark Vector.svg';
//LENNAR BRAND: 2) well pull the lennar logo.
import { ReactComponent as LennarLogo } from '../../vendor/Lennar_Mortgage.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const BrandIcon = styled(Logo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;

const LennarBrandIcon = styled(LennarLogo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;

const Wrapper = styled(Paper)`
	padding: ${(props) => props.theme.spacing(6)};

	${(props) => props.theme.breakpoints.up('md')} {
		padding: ${(props) => props.theme.spacing(10)};
	}
`;

function SignIn() {
	const [open, setOpen] = React.useState(false);
	const [user, setUser] = React.useState();
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Helmet title='Sign In' />
			<Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
				<DialogTitle id='form-dialog-title'>Set New Password</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{
							password: '',
							passwordConfirmation: '',
							email: email,
							phone: phone,
							submit: false,
						}}
						validationSchema={Yup.object().shape({
							password: Yup.string().min(10).max(255).required('Password is required'),
							passwordConfirmation: Yup.string().oneOf(
								[Yup.ref('password'), null],
								'Passwords must match'
							),
							email: Yup.string().email(),
							phone: Yup.string().min(10).max(12),
						})}
						onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
							try {
								//Amplify auth
								//Set new password
								let requiredAttributes = {};
								if (email === '') {
									requiredAttributes['email'] = values.email;
								}
								if (phone === '') {
									requiredAttributes['phone_number'] = `+1${values.phone}`;
								}
								await Auth.completeNewPassword(user, values.password, requiredAttributes)
									.then((user) => {
										// at this time the
										console.log(user);
									})
									.catch((e) => {
										throw e;
									});
							} catch (error) {
								const message = error.message || 'Something went wrong';
								setStatus({ success: false });
								setErrors({ submit: message });
								setSubmitting(false);
							}
						}}>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values,
						}) => (
							<form noValidate onSubmit={handleSubmit}>
								{errors.submit && (
									<Alert mt={2} mb={1} severity='warning'>
										{errors.submit}
									</Alert>
								)}
								<TextField
									variant='standard'
									type='password'
									name='password'
									label='Password'
									value={values.password}
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText='10 char min. w/ at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character'
									onBlur={handleBlur}
									onChange={handleChange}
									my={2}
								/>
								<TextField
									variant='standard'
									type='password'
									name='passwordConfirmation'
									label='Confirm Password'
									value={values.changePassword}
									error={Boolean(
										touched.passwordConfirmation && errors.passwordConfirmation
									)}
									fullWidth
									onBlur={handleBlur}
									onChange={handleChange}
									my={2}
								/>
								{email === '' && (
									<TextField
										variant='standard'
										type='email'
										name='email'
										label='Email Address'
										value={values.email}
										error={Boolean(touched.email && errors.email)}
										fullWidth
										onBlur={handleBlur}
										onChange={handleChange}
										my={2}
									/>
								)}
								{phone === '' && (
									<TextField
										variant='standard'
										type='tel'
										name='phone'
										label='Phone Number'
										value={values.phone}
										error={Boolean(touched.phone && errors.phone)}
										fullWidth
										helperText='ex. 3031234567'
										onBlur={handleBlur}
										onChange={handleChange}
										my={2}
									/>
								)}
								<Button
									type='submit'
									fullWidth
									variant='contained'
									color='primary'
									disabled={isSubmitting}>
									Set Password
								</Button>
							</form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
			<Paper sx={{ p: { xs: 6, md: 10 } }}>
				{/* need to figure out how to switch this dynamically -- redux in app */}
				<BrandIcon />
				<Typography component='h1' variant='h4' align='center' gutterBottom>
					The digital mortgage is just a click away!
				</Typography>
				<Typography component='h2' variant='body1' align='center'>
					Sign in to your account to continue.
					{/* If you do not have an existing account,
				<Link href='/auth/sign-up'>click here</Link> to sign up. */}
				</Typography>
				<Formik
					initialValues={{
						username: '',
						password: '',
						submit: false,
					}}
					validationSchema={Yup.object().shape({
						username: Yup.string().max(255).required('Username is required'),
						password: Yup.string().min(12).max(255).required('Password is required'),
					})}
					onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
						try {
							//Amplify auth
							await Auth.signIn(values.username, values.password).then(async (user) => {
								if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
									//Cognito is forcing some attributes that have to be handled if missing
									setPhone(user.challengeParam.userAttributes.phone_number);
									setEmail(user.challengeParam.userAttributes.email);
									setUser(user);
									setOpen(true);
								}
							});
						} catch (error) {
							const message = error.message || 'Something went wrong';

							setStatus({ success: false });
							setErrors({ submit: message });
							setSubmitting(false);
						}
					}}>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						touched,
						values,
					}) => (
						<form noValidate onSubmit={handleSubmit}>
							{errors.submit && (
								<Alert mt={2} mb={1} severity='warning'>
									{errors.submit}
								</Alert>
							)}
							<TextField
								variant='standard'
								type='username'
								name='username'
								label='Username'
								value={values.username}
								error={Boolean(touched.username && errors.username)}
								fullWidth
								helperText='Ex. johndoe'
								onBlur={handleBlur}
								onChange={handleChange}
								my={2}
							/>
							<TextField
								variant='standard'
								type='password'
								name='password'
								label='Password'
								value={values.password}
								error={Boolean(touched.password && errors.password)}
								fullWidth
								onBlur={handleBlur}
								onChange={handleChange}
								my={2}
							/>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								id='sign-in'
								disabled={isSubmitting}>
								Sign in
							</Button>
							<Button
								component={RouterLink}
								to='/auth/forgot-password'
								fullWidth
								color='primary'>
								Forgot password
							</Button>
						</form>
					)}
				</Formik>
			</Paper>
		</React.Fragment>
	);
}

export default SignIn;
