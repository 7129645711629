import React, { useEffect, useState, Suspense } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Hub, Logger } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import { raygunInit } from './utils/raygun';

import routes from './routes';

import ErrorBoundary from './components/ErrorBoundary';
import Loader from './components/Loader';

import { getTheme } from './redux/theme/themeSlice';
import createTheme from './theme';
import { ThemeProvider } from '@mui/material/styles';

//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
	userLogin,
	userLogout,
	getCompany,
	getEncompassToken,
	setEncompassToken,
	setRequestedPath,
	setCompany,
} from './redux/auth/authSlice';
import {
	setStandardFields,
	setUsers,
	setMe,
	setUserCdo,
	setUserCdoLoanView,
	setLastUserUpdate,
	setLimiterEnabled,
	setLimiterField,
	setLimiterValue,
	setPort,
} from './redux/encompass/encompassSlice';
import {
	setUserGroups,
	getUserGroups as getUserGroupsState,
} from './redux/loanPipeline/loanPipelineSlice';
import {
	setConnectionType,
	updateTokenStatus,
	getTopic,
	getSsf,
	getEncryptionKey,
	getEncryptionIv,
	getWsEndpoint,
	getTokenStatus,
	updateConnectionStatus,
} from './redux/websocket/websocketSlice';
import { getUserGroups } from './services/encompass/settings/getUserGroups';
//todo create this method
import { listUsers } from './services/encompass/userSettings/listUsers';
import { getEncompassStandardFields } from './services/storage/getEncompassStandardFields';
import { getUserDetails } from './utils/getUserDetails';
import { getCompanyInfo } from './utils/getCompanyInfo';
import { me } from './services/encompass/userSettings/me';
import { getUserCdo } from './services/encompass/userSettings/userCdos';
import { EncompassApi } from './services/EncompassApi/EncompassApi';

//Provdes a lot of logging out of the box. Might be useful on dev and prod or perhaps just dev
const logger = new Logger('My-Logger');

function App() {
	const content = useRoutes(routes);
	const dispatch = useDispatch();
	const [user, setUser] = useState(null);
	const [company, fetchCompany] = useState(null);
	let topic = useSelector(getTopic);
	let ssf = useSelector(getSsf);
	let key = useSelector(getEncryptionKey);
	let iv = useSelector(getEncryptionIv);
	let endpoint = useSelector(getWsEndpoint);
	const token = useSelector(getEncompassToken);
	const searchUrl = useLocation().search;
	const portParameter = new URLSearchParams(searchUrl).get('port');
	let tokenStatus = useSelector(getTokenStatus);
	let userGroups = useSelector(getUserGroupsState);
	const [mqtt, seIsMqtt] = useState(null);
	let navigate = useNavigate();

	useEffect(async () => {
		console.log('APP START: ENTERING AUTH LISTENER');
		checkCognitoUser();
		authListener();
		console.log('port?', portParameter);
		dispatch(setPort(portParameter));
	}, []);

	useEffect(async () => {
		console.log('APP START: GETTING USER COMPANY');
		if (user) {
			let userCompany = await getCompanyInfo();
			console.log('APP START: USER COMPANY', userCompany);
			fetchCompany(userCompany);
			dispatch(setCompany(userCompany));
			raygunInit(user);
			dispatch(userLogin(user));
		}
	}, [user])

	async function getSetUserGroups(token) {
		//Retrieve and set user group info in Redux.
		//This info will be used for Task Based Workflow when the assignment is a user group since
		//the data shows just a number, rather than the name of the user group
		try {
			let userGroupInfo = await getUserGroups(token);
			dispatch(setUserGroups(JSON.stringify(userGroupInfo)));
		} catch (err) {
			console.error('Failed to retrieve user group info, error: ', err);
		}
	}


	async function authListener() {
		console.log('APP START: LISTENING TO AUTH');
		//Listen and handle Amplify auth events
		Hub.listen('auth', async (data) => {
			//data will include the Cognito JWT as well as all user payload
			switch (data.payload.event) {
				case 'signIn':
					logger.info('user signed in');
					let signedInUser = await getUserDetails();
					console.log('APP START: USER SIGNED IN', signedInUser);
					setUser(signedInUser);
					break;
				case 'signUp':
					logger.info('user signed up');
					break;
				case 'signOut':
					console.log('user signed out');
					logger.info('user signed out');
					dispatch(userLogout());
					dispatch(setRequestedPath(''));
					break;
				case 'signIn_failure':
					logger.error('user sign in failed');
					break;
				case 'tokenRefresh':
					logger.info('token refresh succeeded');
					break;
				case 'tokenRefresh_failure':
					logger.error('token refresh failed');
					logger.info('token refresh failed');
					setUser(null);
					dispatch(userLogout());
					break;
				default:
					console.log('not signed in');
					logger.error('none');
			}
		});
	}

	async function checkCognitoUser() {
		try {
			let signedInUser = await getUserDetails();
			if (signedInUser.username) {
				dispatch(userLogin(signedInUser));

				let userCompany = await getCompanyInfo();
				dispatch(setCompany(userCompany));

				setUser(signedInUser);
				raygunInit(signedInUser);
			}
		} catch (err) {
			console.log('no user');
		}
	}

	const notistackRef = React.createRef();

	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const theme = useSelector(getTheme);
	return (
		<HelmetProvider>
			<Helmet titleTemplate='%s' defaultTitle='LTK Sign Up' />
			<ThemeProvider theme={createTheme(theme)}>
				<LocalizationProvider dateAdapter={AdapterDateFns }>
					<SnackbarProvider
						ref={notistackRef}
						action={(key) => (
							<IconButton aria-label='dismiss' onClick={onClickDismiss(key)}>
								<CloseIcon />
							</IconButton>
						)}
						maxSnack={3}
						preventDuplicate
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}>
						<ErrorBoundary>
							<Suspense fallback={<Loader />}>{content}</Suspense>
						</ErrorBoundary>
					</SnackbarProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default App;
